<template>
  <base-section id="k-d-a-page-header" space="0">
    <base-img :gradient="gradient" :height="imgHeight" :src="imageSrc" max-width="100%" flat tile>
      <v-row v-if="m_objTitle.strText" align="end" class="ma-0 fill-height" justify="start">
        <v-col cols="10" sm="8">
          <v-main class="pt-12 pb-12" />

          <component
            :is="m_objTitle.strComponent"
            :class="
              getTextClasses(m_objTitle.eType, ['text-left', 'white--text', 'font-weight-black'])
            "
          >
            {{ m_objTitle.strText }}
          </component>
        </v-col>
      </v-row>
    </base-img>
  </base-section>
</template>

<script>
import TextClass from '@/mixins/text-class';
import { HexToRGBA, RGBAtoCSS } from 'vuetify/lib/util/colorUtils';

export default {
  name: 'KDAPageHeader',

  mixins: [TextClass],

  metaInfo() {
    return {
      changed: (meta) => {
        if (meta.titleChunk === 'Home') {
          this.m_strTitle = 'ENGINEERING SOLUTIONS';
        } else {
          this.m_strTitle = meta.titleChunk.toUpperCase();
        }
      }
    };
  },

  data: () => ({
    m_strTitle: ''
  }),

  computed: {
    gradient() {
      const color1 = `${this.$vuetify.theme.themes.light.accent1}CC`;
      const color2 = `${this.$vuetify.theme.themes.light.accent2}CC`;
      const overlay1 = RGBAtoCSS(HexToRGBA(color1));
      const overlay2 = RGBAtoCSS(HexToRGBA(color2));

      return `to right, ${overlay1}, ${overlay2}`;
    },
    imgHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? 550 : 350;
    },
    imageSrc() {
      return this.$route.meta.src;
    },
    m_objTitle() {
      return {
        strText: this.m_strTitle,
        strComponent: 'h1',
        eType: this.eContentType.DISPLAY
      };
    }
  }
};
</script>
